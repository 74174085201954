@keyframes slideIn{
    from{
        top:-250px
    }
    to{
        top:85px
    }
}

@keyframes slideOut{
    from{
        top:85px
    }
    to{
        top:-250px;
    }
}

.slideIn{
    animation: slideIn 0.5s forwards;
}

.slideOut{
    animation: slideOut 0.5s forwards;
}

.embaixo{
    z-index: 1;
}

.cima{
    z-index: 2;
}

.direita{
    right:0
}