
.tratamentos-scroll{
    overflow-y: scroll;
    overflow-x: hidden;
}



@media (max-width: 575.98px) {
    .bg-tratamentos{
        background-color: transparent;
    }
    .acordion-button{
        background-color: #fff;
        

    }
    .tratamentos-scroll{
        overflow-y: hidden;
        overflow-x: hidden;
    }
    
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .bg-tratamentos{
        background-color: transparent;
    }
    .acordion-button{
        background-color: #fff;
        

    }
    .tratamentos-scroll{
        overflow-y: hidden;
        overflow-x: hidden;
    }
    
}


@media (min-width: 768px) and (max-width: 991.98px) {

    .bg-tratamentos{
        background-color: white;
        border-radius: 7px;
    }
    .acordion-button{
        background-color: #fff;
        color:#282D31

    }
    .tratamentos-scroll{
        overflow-y: scroll;
        overflow-x: hidden;
        height: 300px
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .bg-tratamentos{
        background-color: white;
        border-radius: 7px;
    }
    .acordion-button{
        background-color: #fff;
        color:#282D31

    }
    .tratamentos-scroll{
        overflow-y: scroll;
        overflow-x: hidden;
        height: 300px
    }
}


@media (min-width: 1200px) {
    .bg-tratamentos{
        background-color: white;
        border-radius: 7px;
    }
    .acordion-button{
        background-color: #fff;
        color:#282D31,
    }
    .tratamentos-scroll{
        overflow-y: scroll;
        overflow-x: hidden;
        height: 300px
    }
}
