@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins-Bold.ttf");
}

@font-face {
    font-family: "Poppins-Black";
    src: url("../fonts/Poppins-Black.ttf");
}

@font-face {
    font-family: "Lato-Heavy";
    src: url("../fonts/Lato-Heavy.ttf");
}

@font-face {
    font-family: "Lato-Regular";
    src: url("../fonts/Lato-Regular.ttf")
}

body a{
    color: #747474;
    text-decoration: none;
    cursor: pointer;
}

body a:hover{

    text-decoration:none;
    cursor: pointer;
}



.texto-primario-escuro {
    color: #18304E
}

.texto-primario {
    color: #1E3B5F
}
.texto-cinza-body{
    color: #747474;
}

.bg-primario-escuro {
    background-color: #18304E
}

.bg-primario {
    background-color: #1E3B5F
}

.bg-primario-light {
    background-color: #C3D7FF
}

.bg-dark{
    background-color: #282D31;
}

.bg-cinza{
    background-color: #747474;
}

.texto-cinza{
    color:#747474
}

.texto-dark{
    color:#282D31;
}

.texto-regular {
    font-family: "Poppins-Regular";
}

.texto-semibold {
    font-family: "Poppins-SemiBold";
}

.texto-bold {
    font-family: "Poppins-Bold"
}

.texto-black {
    font-family: "Poppins-Black"
}

.texto-lato-heavy{
    font-family: "Lato-Heavy";
}

.texto-lato-regular{
    font-family: "Lato-Regular";
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(1.5);
  padding: 5px;
}


.texto-body{
    font-size: 1.1em;
}

.bg-section-tratamentos-light{
    background-color: #F3F3F3
}

.bg-section-tratamentos-dark{
    background-color: rgba(52, 97, 151, 0.20)
}

.acordion-button-dark{
    background-color: #1E3B5F;
    color:white
}

.radius{
    border-radius: 7px;
}

.light-mode {
    transition: 0.5s
}
.light-mode a:hover{
    color: #1E3B5F
}

.light-mode p{
    color:#747474;
}

.light-mode h1{
    color:#1E3B5F 
}

.light-mode h5{
    color:#282D31
}

.light-mode-header{
    background-color: #1E3B5F
}

.instagram-dark{
    background-color: #162B45
}

.footer-light{
    background-color: #162B45
}

.footer-dark{
    background-color: #1E3B5F
}


.dark-mode{
    background-color:#18304E;
    transition: 0.5s;
}

.dark-mode a:hover{
    color:#C3D7FF
}

.dark-mode p {
    color:#fff
}

.dark-mode a {
    color:#fff
}

.dark-mode h1 {
    color:#fff
}

.dark-mode h5 {
    color:#fff
}

.dark-mode h3 {
    color:#fff
}

.dark-mode h4 {
    color:#fff
}

.dark-mode strong {
    color:#C3D7FF
}

.dark-mode button {
    background-color:#1E3B5F
    
}


