
.lista-btn{
    font-size:1.2em
}

.sobre-dark h1{
    color:#fff
}

.lista-dark li button{
    color:#fff
}



@media (max-width: 575.98px) {
    .bg-sobre{
        background-color:"#ffff"
    }
    .sobre h1{
        color:#1E3B5F
    }
    .sobre p{
        color:#1E3B5F
    }
    .sobre li button{
        color:#1E3B5F;
    }
    .lista-style li button{
        color:#1E3B5F
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .bg-sobre{
        background-color:"#ffff"
    }
    .sobre h1{
        color:#1E3B5F
    }
    .sobre p{
        color:#1E3B5F
    }
    .sobre ul li button{
        color:#1E3B5F
    }
    .lista-style li button{
        color:#1E3B5F
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .bg-sobre{
        background-color:#1E3B5F
    }
    .sobre h1{
        color:#fff
    }
    .sobre p{
        color:#fff
    }
    .sobre ul li button{
        color:#fff
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .bg-sobre{
        background-color:#1E3B5F
    }
    .sobre h1{
        color:#fff
    }
    .sobre p{
        color:#fff
    }
    .sobre ul li button{
        color:#fff
    }
}


@media (min-width: 1200px) {
    .bg-sobre{
        background-color:#1E3B5F
    }

    .sobre h1{
        color:#fff
    }
    .sobre p{
        color:#fff
    }
    .sobre ul li button{
        color:#fff
    }
}
